<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!!address">
			<payment-logo :custom-logo="'crypto.svg'" />
			<coinspaid-address
				v-if="!!address"
				:address="address"
				:account-currency="accountCurrency"
				:currency="form.method"
			/>
		</div>

		<div v-else>
			<div class="cashier-box cashier-box--deposit">
				<payment-logo :custom-logo="'crypto.svg'" />
				<div class="small-width overflow-visible"><payment-wallet /></div>

				<div class="line-separator-wrapper">
					<div class="line-separator" />
				</div>
				<div class="small-width">
					<select
						v-model="form.method"
						class="cashier-form-control"
						:placeholder="[[$t('deposit.methods')]]"
						@change="onMethodChange()"
					>
						<option
							v-for="method in methods"
							:key="method.id"
							:value="method.id"
							:class="{ active: form.method === method.id }"
						>
							{{ method.name }}
						</option>
					</select>
				</div>

				<div class="info-description">
					<div class="input-group account-wrapper">
						<label>{{ $t('deposit.amount') }}</label>
						<div class="form-wrapper">
							<div class="amount-values-wrapper">
								<p class="unsupported" v-if="methods.find((m) => m.id === form.method)">
									{{
										$t('deposit.amount-crypto', {
											currency: methods.find((m) => m.id === form.method).currency,
										})
									}}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div class="info-description">
					<div class="input-group account-wrapper">
						<label>{{ $t('deposit.promocode') }}</label>
						<div class="form-wrapper">
							<div class="amount-values-wrapper">
								<p class="unsupported">
									{{ $t('deposit.promocode-unsupported') }}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div v-if="exchangeRate" class="info-description">
					<h2 class="exchange-title">
						{{ $t('deposit.exchange-rate') }}
					</h2>
					1 {{ methods.find((m) => m.id === form.method).symbol }} = {{ exchangeRate }} USD
				</div>

				<button type="button" class="button primary-button deposit center" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>

				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</div>

			<div class="method-description-cashier">
				{{ $t(`deposit.method-descriptions.coinspaid`) }}
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const availableMethods = [
	{
		id: 'BTC',
		name: 'Bitcoin (BTC)',
		symbol: 'BTC',
		calculateExchangeRate: true,
	},
	{
		id: 'ETH',
		name: 'Ethereum (ETH)',
		symbol: 'ETH',
		calculateExchangeRate: true,
	},
	{
		id: 'BCH',
		name: 'Bitcoin Cash (BCH)',
		symbol: 'BCH',
		calculateExchangeRate: true,
	},
	{
		id: 'USDTE',
		name: 'Tether USD (ERC20)',
		symbol: 'USDT',
		calculateExchangeRate: false,
	},
	{
		id: 'USDTT',
		name: 'Tether USD (TRC20)',
		symbol: 'USDT',
		calculateExchangeRate: false,
	},
	{
		id: 'LTC',
		name: 'Litecoin (LTC)',
		symbol: 'LTC',
		calculateExchangeRate: false,
	},
	{
		id: 'TRON',
		name: 'Tron (TRON)',
		symbol: 'TRON',
		calculateExchangeRate: false,
	},
	{
		id: 'DOGE',
		name: 'Dogecoin (DOGE)',
		symbol: 'DOGE',
		calculateExchangeRate: false,
	},
	{
		id: 'SNACK',
		name: 'Crypto Snack (SNACK)',
		symbol: 'SNACK',
		calculateExchangeRate: false,
	},
	{
		id: 'BUSD',
		name: 'Binance USD (BUSD)',
		symbol: 'BUSD',
		calculateExchangeRate: false,
	},
	{
		id: 'TFL',
		name: 'TFL.io (TFL)',
		symbol: 'TFL',
		calculateExchangeRate: false,
	},
	{
		id: 'TFS',
		name: 'TFS Token (TFS)',
		symbol: 'TFS',
		calculateExchangeRate: false,
	},
	{
		id: 'MRX',
		name: 'Metrix Coin (MRX)',
		symbol: 'MRX',
		calculateExchangeRate: false,
	},
	{
		id: 'CPD',
		name: 'Coinspaid (CPD)',
		symbol: 'CPD',
		calculateExchangeRate: false,
	},
	{
		id: 'CHZ',
		name: 'Chiliz (CHZ)',
		symbol: 'CHZ',
		calculateExchangeRate: false,
	},
	{
		id: 'DAI',
		name: 'Dai (DAI)',
		symbol: 'DAI',
		calculateExchangeRate: false,
	},
	{
		id: 'XED',
		name: 'Exeedme (XED)',
		symbol: 'XED',
		calculateExchangeRate: false,
	},
	{
		id: 'XMR',
		name: 'Monero (XMR)',
		symbol: 'XMR',
		calculateExchangeRate: false,
	},
	{
		id: 'EURS',
		name: 'STATIS EURO (EURS)',
		symbol: 'EURS',
		calculateExchangeRate: false,
	},
	{
		id: 'WTS',
		name: 'WTS',
		symbol: 'WTS',
		calculateExchangeRate: false,
	},
	{
		id: 'BNB',
		name: 'BNB',
		symbol: 'BNB',
		calculateExchangeRate: false,
	},
	{
		id: 'NEO',
		name: 'Neo (NEO)',
		symbol: 'NEO',
		calculateExchangeRate: false,
	},
	{
		id: 'XRP',
		name: 'XRP',
		symbol: 'XRP',
		calculateExchangeRate: false,
	},
	{
		id: 'DASH',
		name: 'Dash (DASH)',
		symbol: 'DASH',
		calculateExchangeRate: false,
	},
	{
		id: 'AZ',
		name: 'Azbit (AZ)',
		symbol: 'AZ',
		calculateExchangeRate: false,
	},
	{
		id: 'AIC',
		name: 'Aladincoin (AIC)',
		symbol: 'AIC',
		calculateExchangeRate: false,
	},
	{
		id: 'USDC',
		name: 'USD Coin (USDC)',
		symbol: 'USDC',
		calculateExchangeRate: false,
	},
];

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const coinspaidAddress = () => import('./CoinspaidAddress');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'Coinspaid',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		coinspaidAddress,
		paymentWallet,
	},
	data() {
		return {
			address: null,
			accountCurrency: null,
			form: {
				method: null,
			},
			methods: [],
			loading: false,
			exchangeRate: null,
		};
	},
	async mounted() {
		await this.getMethods(this.form.method);
		this.form.method = this.methods[0].id;
		await this.fetchExchangeRate(this.form.method);
	},
	methods: {
		async getMethods() {
			const response = await apiClient.getMethodsCoinspaid();

			if (response.status !== 200) {
				throw new Error('Invalid response from get methods');
			}

			this.methods = availableMethods.filter((m) => response.data.methods.find((d) => d === m.id));
		},
		async getAddress(method) {
			const response = await apiClient.getAddressCoinspaid(method);

			if (response.status === 200 || response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from get address');
		},
		async fetchExchangeRate(method) {
			const response = await apiClient.fetchExchangeRate(method);

			if (response.status === 200) {
				this.exchangeRate = response.data.exchangeRate.toFixed(2);
			} else {
				throw new Error('Failed to load exchange rate');
			}
		},
		async pay() {
			this.loading = true;

			try {
				const response = await this.getAddress(this.form.method);

				this.address = response.address;
				this.accountCurrency = response.accountCurrency;
			} catch (e) {
				console.error(e);

				this.$router.push({
					path: '/payments/deposit/confirmation',
					query: { status: 'error' },
				});
				console.error('Failed to fetch user data', e);
			} finally {
				this.loading = false;
			}
		},
		async onMethodChange() {
			const method = this.methods.find((m) => m.id === this.form.method);
			this.exchangeRate = null;

			if (method.calculateExchangeRate) {
				await this.fetchExchangeRate(this.form.method);
			}
		},
	},
};
</script>
<style scoped>
p.unsupported {
	text-align: left;
}

.info-description {
	padding: 10px;
	margin-top: 15px;
}

.cashier-box {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	padding: 20px;
}

.primary-button.deposit {
	margin-top: 20px;
}

.exchange-title {
	padding: 0px;
	margin: 5px;
	font-weight: lighter;
}
</style>
